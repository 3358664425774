.header-mobile_container {
  width: 100vw;
  height: 20vh;
  padding: 6vw;
  z-index: 3;
  position: fixed;
  font-size: var(--font-s);
}

.header-mobile_container h1 {
  font-size: var(--font-s);
  font-weight: 400;
}

.header-mobile_container button {
  padding: 0;
  font-size: var(--font-s);
  border: none;
  background-color: transparent;
  text-align: end;
}

.nav-page_container {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
}

.nav-page_container ul {
  list-style: none;
  padding: 0;
}

.nav-page_navBtn-item button {
  font-size: var(--font-s);
  background-color: transparent;
  border: none;
}

.nav-page-dot {
  height: 80px;
}

.hi {
  width: 120vw;
  height: 120vh;
  background-color: var(--red);
  border-radius: 0;
  animation: navPagePopUp 0.3s;
  position: fixed;
  z-index: 2;
}

@keyframes navPagePopUp {
  from {
    transform: scale(0%);
    transform-origin: 90vw 10vh;
    border-radius: 50%;
  }

  to {
    transform: scale(100%);
    transform-origin: 90vw 10vh;
    border-radius: 0%;
  }
}
