.about-page_container {
  width: 100vw;
  margin-top: 24vh;
}

.about-text_container {
  width: 50vw;
  margin-top: 20vh;
}

.about-wanderfux-text {
  margin: 32px 0;
}

.about-logo-img_wrapper {
  position: relative;
  margin-top: 30vh;
}

.about-logo_container {
  width: 12vw;
  height: auto;
  position: absolute;
  left: 80%;
  top: -20%;
  z-index: 1;
}

.about-logo_container img {
  max-width: 100%;
  height: auto;
}

.about-img_container {
  width: 90vw;
}

.about-img_container img {
  max-width: 100%;
}
