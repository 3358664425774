.blog-page_container {
  width: 100vw;
  margin-top: 24vh;
}

.blog-list_container {
  width: 50vw;
  padding: 0 1vw;
  margin-top: 20vh;
}

.blog-list-header_container {
  height: 20vh;
}

.blog-list-header_container button {
  padding: 0;
  background-color: transparent;
  font-family: "Roboto Mono", monospace;
  font-size: var(--font-m);
  border: none;
}

.blog-list-header_container button:hover {
  color: var(--red);
}

.blog-list {
  padding: 0;
  list-style: none;
}

.blog-logo-img_wrapper {
  width: 96vw;
  margin-top: 16vh;
  position: relative;
}

.blog-logo_container {
  width: 12vw;
  height: auto;
  position: absolute;
  left: 80%;
  top: -20%;
  z-index: 1;
}

.blog-logo_container img {
  width: 100%;
  height: auto;
}

.blog-img_container {
  width: 100%;
}

.blog-img_container img {
  max-width: 100%;
}

.blog-post-item_container {
  height: 10vh;
}

.blog-post-item_container-underline {
  height: 10vh;
  border-bottom: 1px solid var(--black);
}
