footer {
  width: 90vw;
  height: 20vh;
  padding: 60px;
  gap: 32px;
}

.footer-social-media_container {
  gap: 32px;
}

.footer-social-media_container a:hover {
  color: var(--red);
}

.footer-social-media-icon_container {
  max-width: 40px;
  max-height: 40px;
  min-width: 20px;
  min-height: 20px;
  margin-right: 4px;
}

.footer-social-media-icon_container img {
  width: 100%;
  height: 100%;
}
