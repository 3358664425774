.grid-columns_two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.grid-colums_two-extend-two {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.grid-columns_two-extend-one {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.grid-columns_two-extend-auto {
  display: grid;
  grid-template-columns: 1fr auto;
}

.grid-columns_one-extend-auto {
  display: grid;
  grid-template-columns: auto 1fr;
}

.grid-columns_four-expand-one {
  display: grid;
  grid-template-columns: 1.5fr repeat(3, 1fr);
}

.justify-items_start {
  display: grid;
  justify-items: start;
}

.justify-items_center {
  display: grid;
  justify-items: center;
}

.justify-items_end {
  display: grid;
  justify-items: end;
}

.align-items_start {
  display: grid;
  align-items: start;
}

.align-items_center {
  display: grid;
  align-items: center;
}

.align-items_end {
  display: grid;
  align-items: end;
}

.justify-self_end {
  justify-self: end;
}

.place-items_center {
  display: grid;
  place-items: center;
}
