.home-page-mobile_container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.home-mobile-logo-img_wrapper {
  position: relative;
  margin-top: 50vh;
}

.home-mobile-logo_container {
  width: 32vw;
  height: auto;
  position: absolute;
  right: 12%;
  top: -24%;
  z-index: 1;
}

.home-mobile-logo_container img {
  width: 100%;
}

.home-mobile-image_container {
  width: 88vw;
  height: 100%;
}

.home-mobile-image_container img {
  width: 100%;
}
