.home-main_container {
  width: 100vw;
  height: 76vh;
  margin-top: 24vh;
  position: fixed;
}

.home-logo-img_wrapper {
  position: relative;
}

.home-logo_container {
  width: 12vw;
  height: auto;
  position: absolute;
  left: 20%;
  top: -36%;
}

.home-logo_container img {
  width: 100%;
  height: auto;
}

.home-img_container {
  width: 96vw;
}

.home-img_container img {
  width: 100%;
  height: auto;
}
