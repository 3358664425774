.blog-page_container-mobile {
  width: 100vw;
  height: 100vh;
  font-size: var(--font-s);
}

.blog-list_container-mobile {
  width: 100vw;
  margin-top: 30vh;
  padding: 0 6vw;
}

.blog-list-header_container-mobile {
  height: 12vh;
}

.blog-list-header_container-mobile button {
  padding: 0;
  background-color: transparent;
  font-family: "Roboto Mono", monospace;
  font-size: var(--font-s);
  border: none;
}

.blog-list-header_container-mobile button:hover {
  color: var(--red);
}

.blog-logo-img_wrapper-mobile {
  position: relative;
  margin-top: 20vh;
}

.blog-logo_container-mobile {
  width: 32vw;
  height: auto;
  position: absolute;
  right: 12%;
  top: -16%;
  z-index: 1;
}

.blog-logo_container-mobile img {
  max-width: 100%;
  max-height: 100%;
}

.blog-img_container-mobile {
  width: 95vw;
}

.blog-img_container-mobile img {
  width: 100%;
  height: auto;
}

.blog-post-item_container-mobile {
  height: 20vh;
}

.blog-post-item_container-underline-mobile {
  height: 20vh;
  border-bottom: 1px solid var(--black);
}
