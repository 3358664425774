.blog-post-page_container {
  width: 100vw;
  margin-top: 24vh;
}

.blog-post_container {
  width: 50vw;
  margin: 20vh 0;
  padding: 0 1vw;
}

.blog-post-header_container {
  margin-bottom: 4vh;
}

.blog-post-header_container button {
  padding: 0;
  background-color: transparent;
  font-family: "Roboto Mono", monospace;
  font-size: var(--font-m);
  border: none;
  text-align: end;
}

.blog-post-header_container button:hover {
  color: var(--red);
}

.blog-post-content_container ul {
  list-style: none;
  padding: 0;
}

.blog-post-image_container {
  width: 100%;
  margin: 32px 0;
}

.blog-post-content_container img {
  max-width: 100%;
}

.blog-post-element ul {
  list-style: inside;
  padding-left: 40px;
}

.blog-post-element a {
  text-decoration: underline;
}
