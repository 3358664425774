@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap"); /* font-family: 'Roboto Mono', monospace */

* {
  box-sizing: border-box;
}

html {
  --font-xs: 12px;
  --font-s: 14px;
  --font-m: 16px;
  --grey-01: #f2f2f2;
  --red: #ef0000;
  --black: #000000;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto Mono", monospace;
  background-color: var(--grey-01);
}

button {
  cursor: pointer;
  letter-spacing: 0.1em;
  line-height: 2em;
  color: var(--black);
}

input {
  font-family: "Roboto Mono", monospace;
  cursor: pointer;
  line-height: 2em;
}

input:focus {
  outline: none;
}

h1,
p {
  margin: 0;
  line-height: 2em;
}

a {
  cursor: pointer;
  color: var(--black);
  text-decoration: none;
  line-height: 2em;
}

a:hover {
  color: var(--red);
}
