.subscribe-page_container-mobile {
  width: 100vw;
  height: 100vh;
  font-size: var(--font-s);
}

.subscribe-form-mobile {
  width: 88vw;
  height: 12vh;
  margin-top: 30vh;
  padding-bottom: 6vh;
  border-bottom: solid 1px black;
}

.subscribe-form-mobile input {
  padding: 0;
  font-size: var(--font-s);
  border: none;
  background-color: transparent;
}

.subscribe-form-mobile input[type="submit"] {
  color: var(--black);
  font-weight: 400;
}

.subscribe-form-mobile input[type="submit"]:hover {
  color: var(--red);
}
