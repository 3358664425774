.shop-main_container {
  width: 100vw;
  height: 56vh;
  margin-top: 24vh;
}

.shop-main_container-mobile {
  width: 100vw;
  height: 100vh;
  font-size: var(--font-s);
}
