.about-page-mobile_container {
  width: 100vw;
  height: 100vh;
  font-size: var(--font-s);
}

.about-text-mobile_container {
  padding: 0 6vw;
  margin-top: 30vh;
}

.about-text-mobile_container p {
  margin-bottom: 4vh;
}

.about-mobile-logo-img_wrapper {
  position: relative;
  margin-top: 20vh;
}

.about-mobile-logo_container {
  width: 32vw;
  height: auto;
  position: absolute;
  right: 12%;
  top: -16%;
  z-index: 1;
}

.about-mobile-logo_container img {
  width: 100%;
}

.about-mobile-image_container {
  width: 88vw;
  height: 100%;
}

.about-mobile-image_container img {
  width: 100%;
}
